import AppContext from "context/AppContext";
import { useEffect, useContext } from "react";
import { useHistory } from "react-router";

const Navigator = () => {
  const history = useHistory();
  const { setContext, navigateTo } = useContext(AppContext);
  useEffect(() => {
    if (navigateTo && navigateTo !== window.location.pathname) {
      history.push(navigateTo);
      window.scrollTo(0, 0);
    }
  }, [navigateTo, history]);

  useEffect(() => {
    const listener = () => {
      setContext({ navigateTo: window.location.pathname });
    };
    window.addEventListener("popstate", listener);

    return () => {
      window.removeEventListener("popstate", listener);
    };
  }, [setContext]);

  return null;
};

export default Navigator;
