import React from "react";
import * as S from "./ErrorMessage.style";

const ErrorMessage = ({
  children = "Desculpe, ocorreu um erro inesperado.",
  center,
  fullHeight,
}) => {
  return (
    <S.Container fullHeight={fullHeight}>
      <S.ErrorMessage center={center}>{children}</S.ErrorMessage>
    </S.Container>
  );
};

export default ErrorMessage;
