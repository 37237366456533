import styled from "styled-components/macro";

export const Footer = styled.footer(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  marginLeft: 20,
  marginRight: 20,
  paddingBottom: 50,

  [theme.breakpoint.xl()]: {
    margin: 0,
    flexDirection: "row",
    alignItems: "center",
    padding: "30px 60px",
  },

  "@media (min-width: 1480px)": {
    padding: "30px calc((100% - 1260px)/2)",
  },
}));
