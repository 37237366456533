import * as S from "./Desktop.style";
import { useContext } from "react";
import AppContext from "context/AppContext";

const Desktop = ({ goTo }) => {
  const { setContext } = useContext(AppContext);

  return (
    <S.DesktopContainer>
      <S.Icon
        className="make-click"
        data-label="home logo desktop"
        onClick={() => goTo("/")}
        width={147}
        height={76}
      />
      <S.NavContainer>
        <S.LinksContainer>
          <S.Links
            variant="link02-sm"
            color="primary"
            className="make-click"
            data-label="Política de privacidade link"
            onClick={() =>
              window.open(
                "https://csh.my.site.com/portalprivacidade",
                "_blank"
              )
            }
          >
            Política de privacidade
          </S.Links>
          <S.Links
            className="make-click"
            data-label="Trabalhe com a gente link"
            onClick={() => {
              setContext({ showCookiesModal: true });
            }}
            variant="link02-sm"
            color="primary"
          >
            Cookies
          </S.Links>
          <S.Links
            className="make-click"
            data-label="Trabalhe com a gente link"
            onClick={() =>
              window.open("https://csh.gupy.io/", "_blank")
            }
            variant="link02-sm"
            color="primary"
          >
            Trabalhe com a gente
          </S.Links>
          <S.Links
            className="make-click"
            data-label="LinkedIn link desktop"
            onClick={() =>
              window.open(
                "https://www.linkedin.com/company/cnpbrasil",
                "_blank"
              )
            }
            variant="link02-sm"
            color="primary"
          >
            LinkedIn
          </S.Links>
        </S.LinksContainer>

        <S.AddressContainer>
          <S.AddressContent>
            <S.Paragraph variant="body02-md" color="primary">
              Edifício Sede: SHN Quadra 01, Conjunto A, Bloco E 
              <br />Brasília/DF - CEP: 70.701-050
            </S.Paragraph>   
          </S.AddressContent>
          <S.Paragraph variant="body02-md" color="primary">
            A CNP Seguros Holding Brasil é uma parceria da CNP Assurances com a Caixa Econômica Federal
          </S.Paragraph>
        </S.AddressContainer>
      </S.NavContainer>

      <S.CopyCotainer>
          <S.CopyContent>
            <S.Paragraph variant="body02-md" color="primary">
              CNP SEGUROS HOLDING BRASIL S.A.
            </S.Paragraph>
            <S.Paragraph variant="body02-md" color="primary">
              CNPJ 14.045.781/0001-45
            </S.Paragraph>
          </S.CopyContent>

          <S.Paragraph variant="body02-md" color="primary-dark">
              © Copyright {new Date().getFullYear()}
          </S.Paragraph>
      </S.CopyCotainer>
    </S.DesktopContainer>
  );
};

export default Desktop;
