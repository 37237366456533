import React from "react";

import {
  StyledMenu,
  StyledLink,
  StyledList,
  HeaderLink,
  HeaderNav,
  HeaderWrap,
  Container, 
  HeaderText
} from "./SideDrawer.style";

import Backdrop from "components/common/Backdrop";
import { ReactComponent as CloseIconAlt } from "assets/svgs/close-icon-alt.svg";
import { ReactComponent as ArrowLeft } from "assets/svgs/arrow-left.svg";

const SideDrawerOurBusiness = ({ isOpen, close, closeSideDrawer }) => {

  const handleCloses = () => {
    close()
    closeSideDrawer()
  }
  return (
    <>
      <Backdrop
        show={isOpen}
        className="make-click"
        data-label="pano de fundo para fechar gaveta"
        onClick={close}
      />

      <StyledMenu isOpen={isOpen}>
        <HeaderNav onClick={close} header={true}>
            <HeaderWrap onClick={close}>
              <Container>
                <ArrowLeft />
                <HeaderText variant="body02-md" primaryColor={false}>
                    Nosso Negócio
                </HeaderText>
              </Container>
              <CloseIconAlt />
            </HeaderWrap>
        </HeaderNav>
        
        <StyledList> 
          <StyledLink className="make-click" data-label="empresas-grupo mobile link" onClick={() => handleCloses()}>
            <HeaderLink width="100%" to="/balanco-financeiro">
              <HeaderText variant="body02-md"  color="primary-dark">
                Balanço financeiro
              </HeaderText>
            </HeaderLink>
          </StyledLink>
          <StyledLink className="make-click" data-label="corpo-diretivo mobile link" onClick={() => handleCloses()}>
            <HeaderLink width="100%" to="/publicacoes">
              <HeaderText variant="body02-md"  color="primary-dark">
                Publicações
              </HeaderText>
            </HeaderLink>
          </StyledLink>
        </StyledList>
      </StyledMenu>
      
    </>
  );
};

export default SideDrawerOurBusiness;