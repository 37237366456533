import styled from "styled-components/macro";
import { Text } from "@gcs/design-system/react";
import { ReactComponent as Logo } from "assets/svgs/logo-cnp.svg";

export const MobileContainer = styled.div(({ theme }) => ({
  [theme.breakpoint.xl()]: {
    display: "none",
  },
}));

export const MobileContent = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  flexDirection: "column",
  paddingBottom: 30,
  marginBottom: 24,
  borderBottom: `0.5px solid ${theme.color.brandPrimary.light}`,
}));

export const Icon = styled(Logo)({
  padding: "10px 0",
  marginBottom: "20px",
});

export const TextLink = styled(Text)({
  marginBottom: 16,
});

export const MarginTopText = styled.div({
  marginTop: 16,
});

export const Paragraph = styled(Text)(({ theme }) => ({
  fontSize: "12px",
  lineHeight: "150%",
  fontWeight: "500",
}))
