import styled from "styled-components/macro";
import { Text } from "@gcs/design-system/react";

export const ErrorMessage = styled(Text)(({ theme, center }) => ({
  color: theme.color.feedback.error,
  fontSize: "16px",
  textAlign: center ? "center" : "unset",
}));

export const Container = styled.div(({ theme, fullHeight }) => ({
  marginTop: fullHeight ? "50px" : "20px",
  height: fullHeight && "calc(100vh - 532px)",
  minHeight: fullHeight && "300px",

  [theme.breakpoint.xl()]: {
    height: fullHeight && "calc(100vh - 325px)",
  },
}));
