import styled from "styled-components";

export const StyledBackdrop = styled.div(({ theme }) => ({
  width: "100%",
  height: "100%",
  position: "fixed",
  zIndex: 100,
  left: 0,
  top: 0,
  backgroundColor: `${theme.color.neutral["02"]}F5`,
}));
