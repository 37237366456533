import { useContext } from "react";
import AppContext from "context/AppContext";

import * as S from "./Footer.style";
import Desktop from "./Desktop";
import Mobile from "./Mobile";

const Footer = () => {
  const { setContext } = useContext(AppContext);

  const goTo = (path) => {
    setContext({ navigateTo: path });
  };

  return (
    <S.Footer className="make-section" data-section="footer section">
      <Mobile goTo={goTo} />
      <Desktop goTo={goTo} />
    </S.Footer>
  );
};

export default Footer;
