import styled from "styled-components/macro";
import { Text } from "@gcs/design-system/react";
import { ReactComponent as Logo } from "assets/svgs/logo-cnp.svg";

export const Header = styled.header(({ theme }) => ({
  
}));

export const Nav = styled.nav(({ theme, heightauto }) => ({
  display: "flex",
  
  [theme.breakpoint.xl()]: {
    display: "block"
  }
}));

export const AutoBuyNav = styled.nav(({ theme }) => ({

}));

export const Wrap = styled.div(({ theme, heightauto }) => ({
  display: "flex",
  height: heightauto ? "auto" : "unset",
  [theme.breakpoint.xl()]: {
    paddingLeft: "60px",
    paddingTop: "10px",
    justifyContent: "flex-start",
  },
}));

export const Icon = styled(Logo)(({ theme }) => ({
  padding: "10px 0",
  cursor: "pointer",
  height: "53px",
  margin: "auto 0",
  

  [theme.breakpoint.xl()]: {
    display: "none",
  },
}));

export const DesktopIcon = styled(Logo)(({ theme }) => ({
  display: "none",

  [theme.breakpoint.xl()]: {
    display: "block",
    margin: "0",
    height: "76px",
    paddingRight: "50px",
  },
}));

export const AutoBuyIcon = styled(Logo)(({ theme }) => ({
  display: "block",
  height: "76px",
  paddingRight: "50px",
  margin: "0 12px",

  [theme.breakpoint.md()]: {
    margin: "0 24px",
  },

  [theme.breakpoint.lg()]: {
    margin: "0 56px",
  },

  [theme.breakpoint.xl()]: {
    margin: "0 calc((100% - 1560px)/2)",
  },

  "@media (min-width: 1200px) and (max-width: 1600px)": {
    margin: "0 112px",
  },
  
}));

export const HeaderItems = styled.ul(({ theme }) => ({
  display: "none",

  [theme.breakpoint.xl()]: {
    display: "flex",
    listStyle: "none",
    margin: "0",
    padding: "0"
  },
}));

export const HeaderItem = styled.li(({ theme, selected }) => ({
  display: "flex",
  alignItems: "center",

  borderBottom: `4px solid ${
    selected ? theme.color.brandPrimary.light : "transparent"
  }`,
}));

export const HeaderItemDropDown = styled.li(({ theme, selected }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",

  borderBottom: `4px solid ${selected ? theme.color.brandPrimary.light : "transparent"}`,
}));

export const HeaderLink = styled.span(({ theme, width, selected }) => ({
  textDecoration: "none",
  cursor: "pointer",
  display: "block",
  width: "100%",
  textAlign: "center",
  height: "100%",
  padding: "20px 0px",
  
  [theme.breakpoint.xl()]: {
    padding: "30px",
  },

  borderBottom: `4px solid ${
    selected ? theme.color.brandPrimary.light : "transparent"
  }`,
}));

export const HeaderItemsDropDown = styled.ul(({ theme, show }) => ({
  transition: "display 300000ms ease-out",
  padding: "0",
  [theme.breakpoint.xl()]: {
    display: `${show ? 'block' : 'none'}`,
    listStyle: "none",
    margin: "0",
  },
}));

export const HeaderLinkDropDown = styled.span(({ theme, width }) => ({
  height: "100%",
  width: width ? width : "unset",
  cursor: "pointer",
  display: "block",
  
  color: theme.color.brandPrimary.light,
  lineHeight: "150%",
  textDecoration: "underline",
  padding: "12px 0px"
}));

export const TextDropDown = styled.p(({theme}) => ({
  fontSize: "16px",
  padding: "0",
  margin: "0"
}));

export const DropDown = styled.div(({ theme, show }) => ({
  padding: "8px 0px",
  display: "none",
  backgroundColor: "#F4F3F3",

  [theme.breakpoint.xl()]: {
    display: show ? "block" : "none",
    position: 'absolute',
    width: '100%',
    zIndex: "3",
    background: 'rgba(244, 243, 243, 0.96)'
  }
}));

export const Flex = styled.div({
  display: "flex",
  paddingLeft: "60px"
});

export const Hidden = styled.div({
  visibility: "hidden",
  display: "flex",
  flexDirection: "row"
});

export const ItemsDropDown = styled.ul(({ theme }) => ({
  padding: 0
})); 

export const HeaderText = styled(Text)(({ theme }) => ({
  fontSize: "14px"
}))