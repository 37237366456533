import React from "react";
import { PrerenderedComponent } from "react-prerendered-component";

import ErrorBoundary from "../components/common/ErrorBoundary";
import loadable from "@loadable/component";

export const prerenderedLoadable = (dynamicImport) => {
  const LoadableComponent = loadable(dynamicImport);
  return React.memo((props) => (
    <ErrorBoundary>
      <PrerenderedComponent live={LoadableComponent.load()}>
        <LoadableComponent {...props} />
      </PrerenderedComponent>
    </ErrorBoundary>
  ));
};
