import React, { useState } from "react";

import {
  StyledMenu,
  StyledLink,
  StyledList,
  HeaderLink,
  SideDrawerItem,
  HeaderNav,
  HeaderWrap,
  HeaderText
} from "./SideDrawer.style";

import Backdrop from "components/common/Backdrop";
import SideDrawerWhoWeAre from "./SideDrawerWhoWeAre"

import { ReactComponent as ArrowRight } from "assets/svgs/chevron-right.svg";
import { ReactComponent as CloseIconAlt } from "assets/svgs/close-icon-alt.svg";
import SideDrawerOurBusiness from "./SideDrawerOurBusiness";

const SideDrawer = ({ isOpen, close }) => {
  const [showSideDrawerWhoWeAre, setShowSideDrawerWhoWeAre] = useState(false);
  const [showSideDrawerOurBusiness, setShowSideDrawerOurBusiness] = useState(false);

  return (
    <>
      <Backdrop
        show={isOpen}
        className="make-click"
        data-label="pano de fundo para fechar gaveta"
        onClick={close}
      />
      <StyledMenu isOpen={isOpen}>
        <HeaderNav onClick={close} header={true}>
            <HeaderWrap onClick={close}>
              <HeaderText variant="body02-md" primaryColor={false}>
                Menu
              </HeaderText>
              <CloseIconAlt />
            </HeaderWrap>
        </HeaderNav>
        
        <StyledList>  
          <StyledLink className="make-click" data-label="home mobile link" onClick={close}>
            <HeaderLink width="100%" to="/">
              <HeaderText variant="body02-md" color="primary-dark">
                Página inicial
              </HeaderText>
            </HeaderLink>
          </StyledLink>

          <StyledLink className="make-click" data-label="quem-somos mobile link" onClick={() => setShowSideDrawerWhoWeAre(true)}>
            <SideDrawerItem width="100%" flex={true}>
              <HeaderText variant="body02-md" color="primary-dark">
                Quem somos
              </HeaderText>
              <ArrowRight />
            </SideDrawerItem>
          </StyledLink>

          <StyledLink className="make-click" data-label="quem-somos mobile link" onClick={() => setShowSideDrawerOurBusiness(true)}>
            <SideDrawerItem width="100%" flex={true}>
              <HeaderText variant="body02-md" color="primary-dark">
                Nosso negócio
              </HeaderText>
              <ArrowRight />
            </SideDrawerItem>
          </StyledLink>

          <StyledLink className="make-click" data-label="imprensa mobile link" onClick={close}>
            <HeaderLink width="100%" to="/imprensa">
              <HeaderText variant="body02-md" color="primary-dark">
                Imprensa
              </HeaderText>
            </HeaderLink>
          </StyledLink>

          <StyledLink className="make-click" data-label="instituto mobile link" onClick={close}>
            <HeaderLink width="100%" to="/instituto">
              <HeaderText variant="body02-md" color="primary-dark">
                Instituto
              </HeaderText>
            </HeaderLink>
          </StyledLink>

          <StyledLink className="make-click" data-label="fale conosco mobile link" onClick={close}>
            <HeaderLink width="100%" to="/fale-conosco">
              <HeaderText variant="body02-md" color="primary-dark">
                Fale Conosco
              </HeaderText>
            </HeaderLink>
          </StyledLink>

          <StyledLink className="make-click" data-label="canal de ética mobile link" onClick={close}>
            <HeaderLink width="100%" to="/fale-conosco#canal-de-etica">
              <HeaderText variant="body02-md" color="primary-dark">
                Canal de Ética
              </HeaderText>
            </HeaderLink>
          </StyledLink>

        </StyledList>
      </StyledMenu>
      <SideDrawerWhoWeAre 
        isOpen={showSideDrawerWhoWeAre}
        close={() => setShowSideDrawerWhoWeAre(false)}
        closeSideDrawer={close}
      />
      <SideDrawerOurBusiness 
        isOpen={showSideDrawerOurBusiness}
        close={() => setShowSideDrawerOurBusiness(false)}
        closeSideDrawer={close}
      />
    </>
  );
};

export default SideDrawer;
