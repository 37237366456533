import styled from "styled-components/macro";
import { Text } from "@gcs/design-system/react";
import { ReactComponent as Logo } from "assets/svgs/logo-cnp.svg";

export const DesktopContainer = styled.div(({ theme }) => ({
  display: "none",

  [theme.breakpoint.xl()]: {
    display: "flex",
    margin: 0,
    flexDirection: "row",
    padding: "0",
    flexGrow: "1",
    justifyContent: "space-between"
  },
}));

export const CopyCotainer = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "end",
  justifyContent: "space-between"
});

export const CopyContent = styled.div(({ theme }) => ({
  background: theme.color.support["08"],
  textAlign: "end",
}));

export const NavContainer = styled.div({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-around",
  minWidth: "300px",
});

export const LinksContainer = styled.div(({ theme }) => ({
  background: theme.color.support["08"],
  display: "flex",
}));

export const Links = styled(Text)({
  cursor: "pointer",
  marginRight: 64,
  
  "&:last-child": {
    marginRight: 0,
  },
});

export const AddressContainer = styled.div(({ theme }) => ({
  background: theme.color.support["08"],
  wordBreak: "break-word",
  alignItems: "top",
  marginTop: "42px"
}));

export const Icon = styled(Logo)({
  height: "unset",
  padding: "0 0 20px 0",
  margin: "0",
});

export const AddressContent = styled.div(({ theme }) => ({
  marginBottom: 8
}));

export const Paragraph = styled(Text)(({ theme }) => ({
  fontSize: "14px",
  lineHeight: "150%",
  fontWeight: "500",
}))