import * as S from "./Mobile.style";

const Mobile = ({ goTo }) => {
  return (
    <S.MobileContainer>
      <S.MobileContent>
        <S.Icon
          className="make-click"
          data-label="home logo mobile"
          onClick={() => goTo("/")}
          width={162}
          height={95}
        />
        <S.TextLink
          className="make-click"
          data-label="Trabalhe com a gente link mobile"
          onClick={() =>
            window.open("https://csh.gupy.io/", "_blank")
          }
          variant="link02-sm"
          color="primary"
        >
          Trabalhe com a gente
        </S.TextLink>
        
        <S.TextLink
          variant="link02-sm"
          color="primary"
          className="make-click"
          data-label="Política de privacidade link mobile"
          onClick={() =>
            window.open(
              "https://csh.my.site.com/portalprivacidade",
              "_blank"
            )
          }
        >
          Política de privacidade
        </S.TextLink>
        <S.TextLink
          variant="link02-sm"
          color="primary"
          className="make-click"
          data-label="LinkedIn link mobile"
        >
          LinkedIn
        </S.TextLink>
      </S.MobileContent>

      <S.Paragraph variant="body02-md" color="primary">
        CNP SEGUROS HOLDING BRASIL S.A. - CNPJ 14.045.781/0001-45
      </S.Paragraph>
      <S.Paragraph variant="body02-md" color="primary">
        Edifício Sede: SHN Quadra 01, Conjunto A, Bloco E 
        <br /> Brasília/DF - CEP: 70.701-050
      </S.Paragraph>

      <S.MarginTopText variant="body02-md" color="primary">
        <S.Paragraph>
          © Copyright {new Date().getFullYear()}
        </S.Paragraph>
      </S.MarginTopText>
      <S.MarginTopText variant="body02-md" color="primary">
        <S.Paragraph>
          A CNP Seguros Holding Brasil é uma parceria da CNP Assurances com a
          Caixa Econômica Federal
        </S.Paragraph>
      </S.MarginTopText>
    </S.MobileContainer>
  );
};

export default Mobile;
