import { useState } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { ThemeProvider as DSThemeProvider, useTheme } from "@gcs/design-system/react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "style/main.css";
import Header from "components/common/Header";
import Footer from "components/common/Footer";
import Navigator from "components/Navigator";

import AppContext from "context/AppContext";
import { prerenderedLoadable } from "utils/prerenderedLoadable";

const Home = prerenderedLoadable(() => import("pages/Home"));
const About = prerenderedLoadable(() => import("pages/About"));
const Institute = prerenderedLoadable(() => import("pages/Institute"));
const ContactUs = prerenderedLoadable(() => import("pages/ContactUs"));
const Ombudsman = prerenderedLoadable(() => import("pages/Ombudsman"));

const SupplierAndPartnerTraining = prerenderedLoadable(() =>
  import(/* webpackChunkName: "Ombudsman" */ "pages/SupplierAndPartnerTraining")
);
const SupplierAssessment = prerenderedLoadable(() =>
  import(/* webpackChunkName: "Ombudsman" */ "pages/SupplierAssessment")
);
const OurHistory = prerenderedLoadable(() =>
  import(/* webpackChunkName: "OurHistory" */ "pages/OurHistory")
);
const FinancialStatement = prerenderedLoadable(() =>
  import(/* webpackChunkName: "FinancialStatement" */ "pages/FinancialStatement")
);
const Publications = prerenderedLoadable(() =>
  import(/* webpackChunkName: "Ombudsman" */ "pages/Publications")
);
const WhoWeAre = prerenderedLoadable(() =>
  import(/* webpackChunkName: "WhoWeAre" */ "pages/WhoWeAre")
);
const Newsroom = prerenderedLoadable(() =>
  import(/* webpackChunkName: "Newsroom" */ "pages/Newsroom")
);
const Holding = prerenderedLoadable(() =>
  import(/* webpackChunkName: "Holding" */ "pages/Holding")
);
const CorporativeCulture = prerenderedLoadable(() =>
  import(/* webpackChunkName: "CorporativeCulture" */ "pages/CorporativeCulture")
);

const App = () => {
  const theme = "cnp-assurances";
  const [context, setContext] = useState({
    setContext: (newCtx) => {
      setContext((oldCtx) => ({ ...oldCtx, ...newCtx }));
    },
    navigateTo: window.location.pathname,
    showCookiesModal: false,
    showCookieDisclaimer: false,
  });

  return (
    <AppContext.Provider value={context}>
      <DSThemeProvider theme={theme}>
        <ThemeProvider theme={useTheme(theme)}>
          <BrowserRouter>
            <Header />
            <main>
              <Switch>
                  <Route exact path="/fale-conosco" component={ContactUs} />
                  <Route exact path="/cnp-brasil" component={About} />
                  <Route exact path="/instituto" component={Institute} />
                  <Route exact path="/ouvidoria" component={Ombudsman} />
                  <Route exact path="/nossa-historia" component={OurHistory} />
                  <Route exact path="/balanco-financeiro" component={FinancialStatement} />
                  <Route exact path="/publicacoes" component={Publications} />
                  <Route exact path="/quem-somos" component={WhoWeAre} />
                  <Route exact path="/imprensa" component={Newsroom} />
                  <Route exact path="/empresas-grupo" component={Holding} />
                  <Route exact path="/cultura-corporativa" component={CorporativeCulture} />
                  <Route exact path="/publicacoes/solvencia-financeira" component={SupplierAssessment} />
                  <Route exact path="/publicacoes/pld_training" component={SupplierAndPartnerTraining} />
                  <Route path="/" component={Home} />
                  <Redirect from="*" to="/" />
              </Switch>
              <Navigator />
            </main>
          </BrowserRouter>
          <Footer />
        </ThemeProvider>
      </DSThemeProvider>
    </AppContext.Provider>
  );
};

export default App;