import { useState } from "react";
import { useHistory } from "react-router";
import * as S from "./Header.style";
import DrawerToggle from "./SideDrawer/DrawerToggle";
import SideDrawer from "./SideDrawer";

import { ReactComponent as ArrowUp } from "assets/svgs/chevron-up.svg";
import { ReactComponent as ArrowDown } from "assets/svgs/chevron-down.svg";

const cnpBrasilPath = "/cnp-brasil";
const nossoNegocioPath = "/nosso-negocio";
const balancoFinaceiroPath = "/balanco-financeiro";
const estruturaSocietariaPath = "/estrutura-societaria";
const publicacoesPath = "/publicacoes";
const faleConoscoPath = "/fale-conosco";
const institutoPath = "/instituto";
const imprensaPath = "/imprensa";
const quemSomosPath = "/quem-somos";
const nossaHistoriaPath = "/nossa-historia";
const empresasGrupoPath = "/empresas-grupo";
const corpoDiretorPath = "/corpo-diretor";
const culturaCorporativaPath = "/cultura-corporativa";
const canalDeEticaPath = `${faleConoscoPath}#canal-de-etica`;

const Header = () => {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedPath, setSelectedPath] = useState("/");
  const [showWhoWeAreDropDown, setShowWhoWeAreDropDown] = useState(false);
  const [showOurBusinessDropDown, setShowOurBusinessDropDown] = useState(false);

  const selectedWhoWeAreDropDown = 
    selectedPath === quemSomosPath ||
    selectedPath === nossaHistoriaPath ||
    selectedPath === empresasGrupoPath ||
    selectedPath === corpoDiretorPath ||
    selectedPath === culturaCorporativaPath;

  const selectedOurBusinessDropDown = 
    selectedPath === nossoNegocioPath ||
    selectedPath === balancoFinaceiroPath ||
    selectedPath === estruturaSocietariaPath ||
    selectedPath === publicacoesPath;
  
  
  const toggleDropDownHandle = (state, callback) => {
    callback(!state);
  };

  const closeContainers = () => {
    setShowWhoWeAreDropDown(false);
    setShowOurBusinessDropDown(false);
  };

  const toggleWhoWeAreDropDown = () => {
    closeContainers();
    toggleDropDownHandle(showWhoWeAreDropDown, setShowWhoWeAreDropDown)
  };

  const toggleOurBusinessDropDown = () => {
    closeContainers();
    toggleDropDownHandle(showOurBusinessDropDown, setShowOurBusinessDropDown)
  };

  const handleSelectPath = path => { 
    closeContainers();
    setSelectedPath(path);
    history.push(path);
  };

  const hidden = (
    <>
      <a href="/">
        <S.DesktopIcon
          className="make-click"
          data-label="header home logo desktop"
          width={197}
          height={76}
        />
      </a>
      <S.HeaderItems>
        <S.HeaderItem
        >
          <S.HeaderLink>
            <S.HeaderText variant="body02-md" color="primary-dark">
              Página inicial
            </S.HeaderText >
          </S.HeaderLink>
        </S.HeaderItem>
      </S.HeaderItems>
    </>
  )

  const history = useHistory();

  return (
    <S.Header className="make-section" data-section="header section">
      <S.Nav>
        <S.Wrap> 
          <a href="/">
            <S.DesktopIcon
              className="make-click"
              data-label="header home logo desktop"
              width={170}
              height={76}
            />
          </a>

          <S.HeaderItems>
            <S.HeaderItem
              className="make-click"
              data-label="header home link"
              selected={selectedPath === "/"}
              onClick={() => handleSelectPath("/")}
              onMouseEnter={() => closeContainers()}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Página inicial
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
            
            <S.HeaderItem
              className="make-click"
              data-label="header sobre a CNP Brasil Link"
              selected={selectedWhoWeAreDropDown}
              onMouseEnter={() => {
                setSelectedPath(quemSomosPath);
                toggleWhoWeAreDropDown();
              }}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Quem somos {showWhoWeAreDropDown ? <ArrowUp/> : <ArrowDown/>}
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
            
            <S.HeaderItem
              className="make-click"
              data-label="header sobre a CNP Brasil Link"
              selected={selectedOurBusinessDropDown}
              onMouseEnter={() => {
                setSelectedPath(nossoNegocioPath)
                toggleOurBusinessDropDown();
              }}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Nosso negócio {showOurBusinessDropDown ? <ArrowUp/> : <ArrowDown/>}
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>

            <S.HeaderItem
              className="make-click"
              data-label="header instituto link"
              selected={selectedPath === imprensaPath}
              onClick={() => handleSelectPath(imprensaPath)}
              onMouseEnter={() => closeContainers()}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Imprensa
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
            
            <S.HeaderItem
              className="make-click"
              data-label="header instituto link"
              selected={selectedPath === institutoPath}
              onClick={() => handleSelectPath(institutoPath)}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Instituto
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
            
            <S.HeaderItem
              className="make-click"
              data-label="fale conosco link"
              selected={selectedPath === faleConoscoPath}
              onClick={() => handleSelectPath(faleConoscoPath)}
              onMouseEnter={() => closeContainers()}
            >
              <S.HeaderLink>
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Fale conosco
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
            
            <S.HeaderItem
              className="make-click"
              selected={selectedPath === canalDeEticaPath}
              onClick={() => handleSelectPath(canalDeEticaPath)}
              onMouseEnter={() => closeContainers()}
            >
              <S.HeaderLink href="/fale-conosco#canal-de-etica">
                <S.HeaderText variant="body02-md" color="primary-dark">
                  Canal de ética
                </S.HeaderText >
              </S.HeaderLink>
            </S.HeaderItem>
          </S.HeaderItems>
        </S.Wrap>
    
        <S.DropDown 
          show={showWhoWeAreDropDown}
          onMouseLeave={() => closeContainers()}
        >
          <S.Flex>
            <S.Hidden>
              {hidden}
            </S.Hidden>

            <S.ItemsDropDown>
              <S.HeaderItem
                onClick={() => handleSelectPath(quemSomosPath)}
              >
                <S.HeaderLinkDropDown>
                    <S.TextDropDown variant="body02-md">
                      Quem é a CNP Assurances
                    </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>

              <S.HeaderItem
                onClick={() => handleSelectPath(nossaHistoriaPath)}
              >
                <S.HeaderLinkDropDown>
                    <S.TextDropDown variant="body02-md">
                      Nossa História
                    </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>

              <S.HeaderItem
                onClick={() => handleSelectPath(empresasGrupoPath)}
              >
                <S.HeaderLinkDropDown>
                    <S.TextDropDown variant="body02-md">
                      Empresas do Grupo
                    </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>

              <S.HeaderItem
                onClick={() => handleSelectPath(culturaCorporativaPath)}
              >
                <S.HeaderLinkDropDown>
                  <S.TextDropDown variant="body02-md">
                    Cultura Corporativa
                  </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>
            </S.ItemsDropDown>
          </S.Flex>
        </S.DropDown>

        <S.DropDown 
          show={showOurBusinessDropDown}
          onMouseLeave={() => closeContainers()}
        >
          <S.Flex>
            <S.Hidden>
              {hidden}
              <S.HeaderItems>
                <S.HeaderItem
                >
                  <S.HeaderLink>
                    <S.HeaderText variant="body02-md" color="primary-dark">
                      Página inicial
                    </S.HeaderText >
                  </S.HeaderLink>
                </S.HeaderItem>
              </S.HeaderItems>
            </S.Hidden>

            <S.ItemsDropDown>

              <S.HeaderItem
                onClick={() => handleSelectPath(balancoFinaceiroPath)}
              >
                <S.HeaderLinkDropDown>
                    <S.TextDropDown variant="body02-md">
                      Balanço financeiro
                    </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>

              <S.HeaderItem
                onClick={() => handleSelectPath(publicacoesPath)}
              >
                <S.HeaderLinkDropDown>
                  <S.TextDropDown variant="body02-md">
                    Publicações
                  </S.TextDropDown>
                </S.HeaderLinkDropDown>
              </S.HeaderItem>
            </S.ItemsDropDown>
          </S.Flex>
        </S.DropDown>
      
        <DrawerToggle
          className="make-click"
          data-label="ação de abrir gaveta"
          onCLick={() => setShowDrawer(true)}
        />

        <SideDrawer isOpen={showDrawer} 
          close={() => setShowDrawer(false)} 
        />

        <S.Icon
          className="make-click"
          data-label="Ícone CNP link para home"
          heightauto="true"
        />
      </S.Nav>
    </S.Header>
  );
};

export default Header;
