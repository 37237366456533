import ErrorMessage from "components/ErrorMessage/ErrorMessage";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: { message: "", stack: "" },
      info: { componentStack: "" },
    };
  }

  static getDerivedStateFromError = (error) => {
    return { hasError: true };
  };

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    return hasError ? (
      <ErrorMessage center fullHeight>
        Desculpe, ocorreu um erro inesperado.
      </ErrorMessage>
    ) : (
      children
    );
  }
}

export default ErrorBoundary;
