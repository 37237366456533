import styled from "styled-components";
import { Link } from "react-router-dom";
import { Text } from "@gcs/design-system/react";
import { ReactComponent as CloseIcon } from "assets/svgs/close-icon.svg";
import { ReactComponent as Logo } from "assets/svgs/logo-cnp.svg";

export const StyledMenu = styled.nav(({ theme, isOpen }) => ({
  position: "fixed",
  width: "328px",
  maxWidth: "85%",
  height: "100%",
  left: 0,
  top: 0,
  zIndex: isOpen ? 200 : -1,
  backgroundColor: theme.color.neutral["08"],
  transition: "transform 0.3s ease-out, z-index 0.2s ease-out",
  transform: isOpen ? "translateX(0)" : "translateX(-100%)",
  listStyleType: "none",
  margin: 0,
}));

export const StyledList = styled.ul(() => ({
  margin: 0,
  listStyleType: "none",
  display: "flex",
  flexFlow: "column",
  alignItems: "start",
  padding: "0px 16px",
}));

export const StyledLink = styled.li(({ theme }) => ({
  color: theme.color.brandPrimary.light,
  borderBottom: `1px solid ${theme.color.line.medium}`,
  width: "100%",
}));

export const StyledCloseIcon = styled(CloseIcon)(({ isopen }) => ({
  position: "absolute",
  right: "-40px",
  top: "10px",
  display: isopen ? "block" : "none",
}));

export const Icon = styled(Logo)(() => ({
  padding: "10px 0",
  cursor: "pointer",
  height: "63px",
  display: "block",
  position: "inherit",
}));

export const HeaderLink = styled(Link)(({ theme, width }) => ({
  textDecoration: "none",
  cursor: "pointer",
  display: "block",
  width: width ? width : "unset",
  height: "100%",
  padding: "20px 0px",
  [theme.breakpoint.xl()]: {
    padding: "30px",
  },
}));

export const SideDrawerItem = styled.div(({ theme, width, flex = false }) => ({
  textDecoration: "none",
  cursor: "pointer",
  display: !flex ? "block" : "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: width ? width : "unset",
  height: "100%",
  padding: "20px 0px",
  [theme.breakpoint.xl()]: {
    padding: "30px",
  },
}));

export const HeaderItemsDropDown = styled.ul(({ theme, show }) => ({
  transition: "display 300000ms ease-out",
  display: `${show ? 'block' : 'none'}`,
  padding: "0",
  [theme.breakpoint.xl()]: {
    
    listStyle: "none",
    margin: "0",
  },
}));

export const HeaderLinkDropDown = styled(Link)(({ theme, width }) => ({
  cursor: "pointer",
  display: "block",
  width: width ? width : "unset",
  height: "100%",
  
  lineHeight: "150%",
  color: theme.color.brandPrimary.light,
  textDecoration: "underline",
  padding: "6px 0px"
}));

export const TextDropDown = styled.p(({theme}) => ({
  fontSize: "12px",
  padding: "0",
  margin: "0"
}))

export const HeaderItem = styled.li(({ theme, selected }) => ({
  display: "flex",
  alignItems: "center",

  borderBottom: `4px solid ${
    selected ? theme.color.brandPrimary.light : "transparent"
  }`,
}));

export const HeaderNav = styled.li(({ theme }) => ({
  backgroundColor: theme.color.brandPrimary.light,
  padding: "0px 16px",  
  color: "white",
}));

export const HeaderWrap = styled.div(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "20px 0px"
}));

export const Container = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  gap: "12px"
}));

export const HeaderText = styled(Text)(({ theme, primaryColor = true }) => ({
  color: `${primaryColor ? theme.color.brandPrimary.light : "#fff"}`,
  fontSize: "14px",
}));